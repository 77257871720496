import router from '@/router'
import InvoiceService from '@/services/InvoiceService.js'

export const namespaced = true

export const state = {
  paymentTerms: [],
  isFetchingPaymentTerms: false,
  isCreatingInvoice: false,
  dialog: {
    show: false,
    isLoading: false,
    invoiceId: null, // Sätts innan invoice hämtats, för att säkerställa att rätt request skriver över data i dialogen
    invoice: null
  },
  isLoadingRegisterPayment: false,
  isLoadingTerminalPayment: false,
  isLoadingReverseApplicationIds: [],
  isLoadingReversePaymentIds: [],
  isLoadingPayment: false,
  isLoadingRegisterCreditInvoiceIds: [], // faktura id för vilka kreditfakturor håller på att skapas
  isLoadingConvertToCredit: false,
  isSendingInvoiceIds: [],
  isAwaitingEmailSavedInvoiceIds: [],

  triggerInvoiceUpdated: 0,
  triggerPaymentApplicationUpdated: 0,
  triggerPaymentUpdated: 0,
  triggerCustomerCreditUpdated: 0,
  triggerRetryPayment: 0
}

export const mutations = {
  SET_PAYMENT_TERMS (state, val) {
    state.paymentTerms = val
  },
  SET_IS_FETCHING_PAYMENT_TERMS (state, val) {
    state.isFetchingPaymentTerms = val
  },
  SET_IS_CREATING_INVOICE (state, val) {
    state.isCreatingInvoice = val
  },
  SET_SHOW_DIALOG (state, val) {
    state.dialog.show = val
  },
  SET_DIALOG_IS_LOADING (state, val) {
    state.dialog.isLoading = val
  },
  SET_DIALOG_FETCHING_CHUNKS (state, payload) {
    if (payload.isFetching) {
      const i = state.dialog.fetchingChunks.findIndex(id => id === payload.chunkName)
      if (i < 0) {
        state.dialog.fetchingChunks.push(payload.chunkName)
      }
    } else {
      const i = state.dialog.fetchingChunks.findIndex(id => id === payload.chunkName)
      state.dialog.fetchingChunks.splice(i, 1)
    }
  },
  SET_DIALOG_INVOICE (state, val) {
    state.dialog.invoice = val
  },
  SET_DIALOG_INVOICE_ID (state, val) {
    state.dialog.invoiceId = val
  },
  ADD_DIALOG_INVOICE_EMAIL (state, val) {
    const index = state.dialog.invoice.emails.findIndex(email => {
      return email.id === val.id
    })
    if (index >= 0) {
      state.dialog.invoice.emails.splice(index, 1, val)
    } else {
      state.dialog.invoice.emails.push(val)
    }
  },
  SET_IS_LOADING_REGISTER_PAYMENT (state, val) {
    state.isLoadingRegisterPayment = val
  },
  SET_IS_LOADING_TERMINAL_PAYMENT (state, val) {
    state.isLoadingTerminalPayment = val
  },
  SET_IS_LOADING_CONVERT_TO_CREDIT (state, val) {
    state.isLoadingConvertToCredit = val
  },
  SET_IS_LOADING_REVERSE_PAYMENT_APPLICATION_IDS (state, payload) {
    if (payload.val) {
      const i = state.isLoadingReverseApplicationIds.findIndex(id => id === payload.paymentApplicationId)
      if (i < 0) {
        state.isLoadingReverseApplicationIds.push(payload.paymentApplicationId)
      }
    } else {
      const i = state.isLoadingReverseApplicationIds.findIndex(id => id === payload.paymentApplicationId)
      state.isLoadingReverseApplicationIds.splice(i, 1)
    }
  },
  SET_IS_LOADING_REVERSE_PAYMENT_IDS (state, payload) {
    if (payload.val) {
      const i = state.isLoadingReversePaymentIds.findIndex(id => id === payload.paymentId)
      if (i < 0) {
        state.isLoadingReversePaymentIds.push(payload.paymentId)
      }
    } else {
      const i = state.isLoadingReversePaymentIds.findIndex(id => id === payload.paymentId)
      state.isLoadingReversePaymentIds.splice(i, 1)
    }
  },
  SET_IS_LOADING_REGISTER_CREDIT_INVOICE_IDS (state, payload) {
    if (payload.val) {
      const i = state.isLoadingRegisterCreditInvoiceIds.findIndex(id => id === payload.originalInvoiceId)
      if (i < 0) {
        state.isLoadingRegisterCreditInvoiceIds.push(payload.originalInvoiceId)
      }
    } else {
      const i = state.isLoadingRegisterCreditInvoiceIds.findIndex(id => id === payload.originalInvoiceId)
      state.isLoadingRegisterCreditInvoiceIds.splice(i, 1)
    }
  },
  SET_IS_LOADING_PAYMENT (state, val) {
    state.isLoadingPayment = val
  },
  TRIGGER_RETRY_PAYMENT (state, val) {
    state.triggerRetryPayment++
  },
  SET_IS_SENDING_INVOICE_IDS (state, payload) {
    if (payload.val) {
      const i = state.isSendingInvoiceIds.findIndex(id => id === payload.invoiceId)
      if (i < 0) {
        state.isSendingInvoiceIds.push(payload.invoiceId)
      }
    } else {
      const i = state.isSendingInvoiceIds.findIndex(id => id === payload.invoiceId)
      state.isSendingInvoiceIds.splice(i, 1)
    }
  },
  SET_IS_AWAITING_EMAIL_SAVED_INVOICE_ID (state, payload) {
    if (payload.val) {
      const i = state.isAwaitingEmailSavedInvoiceIds.findIndex(id => id === payload.invoiceId)
      if (i < 0) {
        state.isAwaitingEmailSavedInvoiceIds.push(payload.invoiceId)
      }
    } else {
      const i = state.isAwaitingEmailSavedInvoiceIds.findIndex(id => id === payload.invoiceId)
      state.isAwaitingEmailSavedInvoiceIds.splice(i, 1)
    }
  },
  TRIGGER_INVOICE_UPDATED (state) {
    state.triggerInvoiceUpdated++
  },
  TRIGGER_PAYMENT_APPLICATION_UPDATED (state) {
    state.triggerPaymentApplicationUpdated++
  },
  TRIGGER_PAYMENT_UPDATED (state) {
    state.triggerPaymentUpdated++
  },
  TRIGGER_CUSTOMER_CREDIT_UPDATED (state) {
    state.triggerCustomerCreditUpdated++
  }
}

export const actions = {
  fetchPaymentTerms ({ commit }) {
    commit('SET_IS_FETCHING_PAYMENT_TERMS', true)
    return InvoiceService.getPaymentTerms()
      .then(({ data }) => {
        window.enrich.enrichPaymentTerm(data.data.payment_terms)
        commit('SET_PAYMENT_TERMS', data.data.payment_terms)
        commit('SET_IS_FETCHING_PAYMENT_TERMS', false)
      })
  },
  openDialogById ({ state, commit }, { invoiceId }) {
    commit('SET_DIALOG_INVOICE_ID', invoiceId) // dialog.invoiceId garanterar att vi inte skriver över data i invoice dialog med data tillhörande en annan invoice
    commit('SET_DIALOG_INVOICE', null)
    commit('SET_DIALOG_IS_LOADING', true)
    commit('SET_SHOW_DIALOG', true)

    return InvoiceService.getInvoice(invoiceId)
      .then(({ data }) => {
        if (data.status === 'success' && invoiceId === state.dialog.invoiceId) {
          window.enrich.enrichInvoice(data.data.invoice)
          commit('SET_DIALOG_INVOICE', data.data.invoice)
        }
      })
      .finally(() => {
        if (invoiceId === state.dialog.invoiceId || !state.dialog.show) {
          commit('SET_DIALOG_IS_LOADING', false)
        }
      })
  },
  createInvoice ({ commit, dispatch }, { customer, booking, paymentTerm, invoiceMessage, orderReference, dueDate, charges, customerCreditAmount, accommodationChargesAmount = 0, isCashInvoice = false, paymentMethod, shouldCheckOut = false, shouldSendEmail = true }) {
    commit('SET_IS_CREATING_INVOICE', true)
    const bookingId = booking ? booking.id : null
    if (isCashInvoice) {
      commit('posCheckout/SET_IS_LOADING_PAYMENT', true, { root: true })
      if (paymentMethod && paymentMethod.gateway === 'integrated_terminal') {
        commit('posTerminal/SET_TERMINAL_CANCELLING', false, { root: true })
        commit('SET_IS_LOADING_TERMINAL_PAYMENT', true)
      }
    }
    return InvoiceService.createInvoice({ customer, bookingId, paymentTerm, invoiceMessage, orderReference, dueDate, charges, customerCreditAmount, accommodationChargesAmount, isCashInvoice, paymentMethod, shouldCheckOut, shouldSendEmail })
      .then(({ data }) => {
        if (data.status === 'success') {
          if (router.history.current.name === 'PosRegister') {
            // resettar varukorgen och kunden i kassan om fakturerat från kassan
            dispatch('posCart/reset', null, { root: true })
            commit('posRegister/SET_CUSTOMER', null, { root: true })
          }
          // öppnar customer eller booking dialog med faktura-tabben öppen
          if (shouldCheckOut) {
            router.push({ name: 'ActionsItems' })
          } else if (data.data.booking_id) {
            dispatch('booking/openDialogById', { bookingId: data.data.booking_id, startTab: 'invoices' }, { root: true })
          } else {
            dispatch('customer/openDialogById', { customerId: data.data.customer_id, startTab: 'invoices' }, { root: true })
          }
        } else {
          if (data.error_code === 'nets_failure') {
            if (data.error_message === 'Terminal / cancelled') { // När kassören avbryter behövs inte återkoppling på samma sätt
              commit('posTerminal/SET_TERMINAL_ERROR', null, { root: true })
              commit('posTerminal/SET_TERMINAL_MESSAGE', null, { root: true })
            } else {
              commit('posTerminal/SET_TERMINAL_ERROR', data.data.print, { root: true })
              commit('posTerminal/SET_TERMINAL_MESSAGE', data.data.failure_message, { root: true })
            }
            commit('posTerminal/SET_TERMINAL_CANCELLING', false, { root: true })
          }
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_TERMINAL_PAYMENT', false)
        commit('posTerminal/SET_TERMINAL_ACTIVE', false, { root: true })
        commit('posTerminal/SET_TERMINAL_CANCELLING', false, { root: true })
        commit('SET_IS_CREATING_INVOICE', false)
        commit('posCheckout/SET_IS_LOADING_PAYMENT', false, { root: true })
      })
  },
  registerInvoicePayment ({ commit, state }, { invoice, paymentMethod, account, date, amount }) {
    commit('SET_IS_LOADING_REGISTER_PAYMENT', true)
    return InvoiceService.registerInvoicePayment({ invoiceId: invoice.id, paymentMethodId: paymentMethod.id, accountId: account.id, date, amount })
      .then(({ data }) => {
        if (data.status === 'success') {
          // skriv över invoice, kontrollerar först att inte ny invoice har öppnats i dialogen
          if (data.data.invoice.id === state.dialog.invoice.id) {
            window.enrich.enrichInvoice(data.data.invoice)
            commit('SET_DIALOG_INVOICE', data.data.invoice)
            commit('TRIGGER_INVOICE_UPDATED')
            commit('TRIGGER_CUSTOMER_CREDIT_UPDATED')
          }
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_REGISTER_PAYMENT', false)
      })
  },
  createCreditInvoice ({ commit, state }, { creditSalesItems, originalInvoiceId }) {
    // Skapa kreditfaktura och applicera kredit direkt på en faktura - inte till för att skapa kreditfaktura från kassan
    commit('SET_IS_LOADING_REGISTER_CREDIT_INVOICE_IDS', { originalInvoiceId, val: true })
    return InvoiceService.createCreditInvoice({ creditSalesItems, originalInvoiceId })
      .then(({ data }) => {
        if (data.status === 'success') {
          // skriv över invoice, kontrollerar först att inte ny invoice har öppnats i dialogen
          if (data.data.invoice.id === state.dialog.invoice.id) {
            window.enrich.enrichInvoice(data.data.invoice)
            commit('SET_DIALOG_INVOICE', data.data.invoice)
            commit('TRIGGER_INVOICE_UPDATED')
          }
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_REGISTER_CREDIT_INVOICE_IDS', { originalInvoiceId, val: false })
      })
  },
  reverseInvoicePayment ({ commit, state }, { paymentId, invoiceId }) {
    commit('SET_IS_LOADING_REVERSE_PAYMENT_IDS', { paymentId, val: true })
    return InvoiceService.reversePayment({ paymentId, invoiceId })
      .then(({ data }) => {
        if (data.status === 'success') {
          // skriv över invoice, kontrollerar först att inte ny invoice har öppnats i dialogen
          if (data.data.invoice.id === state.dialog.invoice.id) {
            window.enrich.enrichInvoice(data.data.invoice)
            commit('SET_DIALOG_INVOICE', data.data.invoice)
            commit('TRIGGER_INVOICE_UPDATED')
          }
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_REVERSE_PAYMENT_IDS', { paymentId, val: false })
        commit('TRIGGER_PAYMENT_UPDATED')
        commit('TRIGGER_CUSTOMER_CREDIT_UPDATED')
      })
  },
  reversePayment ({ commit, state }, paymentId) {
    commit('SET_IS_LOADING_REVERSE_PAYMENT_IDS', { paymentId, val: true })
    return InvoiceService.reversePayment({ paymentId })
      .then(({ data }) => {
        if (data.status === 'success') {
          // TODO:
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_REVERSE_PAYMENT_IDS', { paymentId, val: false })
        commit('TRIGGER_PAYMENT_UPDATED')
      })
  },
  reverseInvoicePaymentApplication ({ commit, state }, paymentApplicationId) {
    commit('SET_IS_LOADING_REVERSE_PAYMENT_APPLICATION_IDS', { paymentApplicationId, val: true })
    return InvoiceService.reverseInvoicePaymentApplication({ paymentApplicationId })
      .then(({ data }) => {
        if (data.status === 'success') {
          // skriv över invoice, kontrollerar först att inte ny invoice har öppnats i dialogen
          if (data.data.invoice.id === state.dialog.invoice.id) {
            window.enrich.enrichInvoice(data.data.invoice)
            commit('SET_DIALOG_INVOICE', data.data.invoice)
            commit('TRIGGER_INVOICE_UPDATED')
          }
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_REVERSE_PAYMENT_APPLICATION_IDS', { paymentApplicationId, val: false })
        commit('TRIGGER_PAYMENT_APPLICATION_UPDATED')
        commit('TRIGGER_CUSTOMER_CREDIT_UPDATED')
      })
  },
  initPayment ({ state, rootState, commit }, { invoice, paymentMethod, amount, customerCreditAmount }) {
    commit('posTerminal/SET_TERMINAL_CANCELLING', false, { root: true })
    commit('SET_IS_LOADING_PAYMENT', true)
    if (paymentMethod && paymentMethod.gateway === 'integrated_terminal') {
      commit('SET_IS_LOADING_TERMINAL_PAYMENT', true)
    }
    let paymentMethodId
    if (paymentMethod === null) {
      paymentMethodId = null
    } else {
      paymentMethodId = paymentMethod.id
    }
    const invoiceId = invoice.id
    const workstationId = rootState.workstation.id
    return InvoiceService.checkout({ invoiceId, paymentMethodId, workstationId, amount, customerCreditAmount })
      .then(({ data }) => {
        if (data.status === 'success') {
          // skriv över invoice, kontrollerar först att inte ny invoice har öppnats i dialogen
          if (data.data.invoice.id === state.dialog.invoice.id) {
            window.enrich.enrichInvoice(data.data.invoice)
            commit('SET_DIALOG_INVOICE', data.data.invoice)
            commit('TRIGGER_INVOICE_UPDATED')
            commit('TRIGGER_CUSTOMER_CREDIT_UPDATED')
          }
        } else {
          if (data.error_code === 'nets_failure') {
            if (data.error_message === 'Terminal / cancelled') { // När kassören avbryter behövs inte återkoppling på samma sätt
              commit('posTerminal/SET_TERMINAL_ERROR', null, { root: true })
              commit('posTerminal/SET_TERMINAL_MESSAGE', null, { root: true })
            } else {
              commit('posTerminal/SET_TERMINAL_ERROR', data.data.print, { root: true })
              commit('posTerminal/SET_TERMINAL_MESSAGE', data.data.failure_message, { root: true })
            }
            commit('posTerminal/SET_TERMINAL_CANCELLING', false, { root: true })
          }
        }
      })
      .finally(() => {
        commit('posTerminal/SET_TERMINAL_CANCELLING', false, { root: true })
        commit('posTerminal/SET_TERMINAL_ACTIVE', false, { root: true })
        commit('SET_IS_LOADING_PAYMENT', false)
        commit('SET_IS_LOADING_TERMINAL_PAYMENT', false)
      })
  },
  resendInvoice ({ state, commit }, { invoiceId }) {
    commit('SET_IS_SENDING_INVOICE_IDS', { invoiceId, val: true })
    return InvoiceService.resendInvoice({ invoiceId })
      .then(({ data }) => {
        // snackbar bekräftelse från servern
        if (data.status === 'success' && state.dialog.invoice.id === invoiceId) {
          // bokningen är öppen i dialogen
          commit('SET_IS_AWAITING_EMAIL_SAVED_INVOICE_ID', { invoiceId, val: true })
        }
      })
      .finally(() => {
        commit('SET_IS_SENDING_INVOICE_IDS', { invoiceId, val: false })
      })
  },
  convertToCredit ({ commit }, { invoiceId }) {
    commit('SET_IS_LOADING_CONVERT_TO_CREDIT', true)
    return InvoiceService.convertToCredit({ invoiceId })
      .then(({ data }) => {
        if (data.status === 'success') {
          // skriv över invoice, kontrollerar först att inte ny invoice har öppnats i dialogen
          if (data.data.invoice.id === state.dialog.invoice.id) {
            window.enrich.enrichInvoice(data.data.invoice)
            commit('SET_DIALOG_INVOICE', data.data.invoice)
            commit('TRIGGER_INVOICE_UPDATED')
          }
        }
      })
      .finally(() => {
        commit('TRIGGER_PAYMENT_APPLICATION_UPDATED')
        commit('TRIGGER_CUSTOMER_CREDIT_UPDATED')
        commit('SET_IS_LOADING_CONVERT_TO_CREDIT', false)
      })
  }
}

export const getters = {
  activePaymentTerms: function (state) {
    if (state.paymentTerms.length === 0) {
      return []
    }
    const activePaymentTerms = state.paymentTerms.filter(paymentTerm => {
      return paymentTerm.deleted_at === null
    })
    return activePaymentTerms
  }
}
