<template>
  <v-list subheader class="booking-data-list">
    <template v-if="!hiddenSections.includes('booking')">
      <template v-if="isLoading">
        <div aria-busy="true" aria-live="polite" role="alert" class="v-skeleton-loader v-skeleton-loader--is-loading theme--light" style="max-width: 300px;">
          <div class="v-skeleton-loader__list-item-avatar v-skeleton-loader__bone">
            <div class="v-skeleton-loader__avatar v-skeleton-loader__bone"></div>
            <div style="margin-top: -8px; width: 160px; max-width: 160px;" class="v-skeleton-loader__text v-skeleton-loader__bone"></div>
            <div style="position: absolute; left: 72px; top: 32px; width: 212px; height: 8px;" class="v-skeleton-loader__text v-skeleton-loader__bone"></div>
          </div>
        </div>
      </template>
      <template v-else>
        <v-list-item @click.native="openBooking" :ripple="bookingClickable" :class="{ 'cursor-pointer highlight-on-hover': bookingClickable }">
          <v-list-item-icon style="margin-right: 29px;">
            <v-list-item-avatar
              color="deep-purple"
              class="justify-center mr-0 my-0"
              size="30"
              style="margin-left: -3px;"
            >
            <!-- <span style="color: white;">BC</span> -->
            <v-icon color="white" small>mdi-book-marker-outline</v-icon>
            </v-list-item-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <span>{{ booking.booking_number }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-show="hasBookingRemarks"
                    aria-hidden="false"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-tag-text-outline
                  </v-icon>
                </template>
                <div style="padding: 4px;">
                  <h3>Bokningsanteckningar:</h3>
                  <i>{{ booking.remarks }}</i>
                </div>
              </v-tooltip>
            </v-list-item-title>
            <v-list-item-subtitle style="width: auto;">Bokningsnummer</v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>
      </template>
    </template>

    <template v-if="!hiddenSections.includes('customer')">
      <template v-if="isLoading">
        <div aria-busy="true" aria-live="polite" role="alert" class="v-skeleton-loader v-skeleton-loader--is-loading theme--light" style="max-width: 300px;">
          <div class="v-skeleton-loader__list-item-avatar v-skeleton-loader__bone">
            <div class="v-skeleton-loader__avatar v-skeleton-loader__bone"></div>
            <div style="margin-top: -8px; width: 160px; max-width: 160px;" class="v-skeleton-loader__text v-skeleton-loader__bone"></div>
            <div style="position: absolute; left: 72px; top: 32px; width: 212px; height: 8px;" class="v-skeleton-loader__text v-skeleton-loader__bone"></div>
          </div>
        </div>
      </template>
      <template v-else>
        <v-list-item @click.native="openCustomer" :ripple="customerClickable" :class="{ 'cursor-pointer highlight-on-hover': customerClickable }">
          <v-list-item-icon style="margin-right: 29px;">
            <customer-avatar :customer="booking.customer" style="margin-left: -3px;"></customer-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="booking.customer.full_name"></v-list-item-title>
            <v-list-item-subtitle v-text="booking.customer.shortAddress"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>

    <template v-if="!hiddenSections.includes('position')">
      <template v-if="isLoading">
        <v-skeleton-loader
          type="list-item-avatar"
          style="max-width: 300px;"
        ></v-skeleton-loader>
      </template>
      <template v-else>
        <v-list-item @click.native="gotoPosition" :ripple="positionClickable" :class="{ 'cursor-pointer highlight-on-hover': positionClickable }">
          <v-list-item-icon style="margin-right: 29px;">
            <v-avatar
              :color="booking.position.avatar.color"
              size="30"
              style="margin-left: -3px;"
            >
              <v-icon color="white" small>{{ booking.position.avatar.icon }}</v-icon>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-title class="flex-shrink-1 flex-grow-0" style="flex-basis: auto;">
            {{ booking.position.full_name }}
          </v-list-item-title>
          <v-list-item-icon class="flex-shrink-0 flex-grow-1 mr-auto" style="height: 30px;">
            <template v-if="isCustomerSelectedPosition">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    aria-hidden="false"
                    class="d-flex justify-align ml-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-map-marker-account
                  </v-icon>
                </template>
                <div style="padding: 4px;">
                  Valt specifik plats
                </div>
              </v-tooltip>
            </template>
            <template v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    aria-hidden="false"
                    class="d-flex justify-align ml-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-dice-multiple
                  </v-icon>
                </template>
                <div style="padding: 4px;">
                  Slumpmässigt tilldelad plats
                </div>
              </v-tooltip>
            </template>

            <template v-if="hasPositionRemarks">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    aria-hidden="false"
                    class="d-flex justify-align ml-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-tag-text-outline
                  </v-icon>
                </template>
                <div style="padding: 4px;">
                  <h3>Platsanteckningar:</h3>
                  <i>{{ booking.position.remarks }}</i>
                </div>
              </v-tooltip>
            </template>
          </v-list-item-icon>
        </v-list-item>
      </template>
    </template>

    <template v-if="!hiddenSections.includes('dates')">
      <template v-if="isLoading">
        <v-list-item>
          <v-list-item-icon class="my-auto">
            <v-icon>mdi-calendar-arrow-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <v-skeleton-loader
                type="list-item"
                class="skeleton-loader-title-with-subtitle"
              ></v-skeleton-loader>
            </v-list-item-title>
            <v-list-item-subtitle>Incheckning</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="my-auto">
            <v-icon>mdi-calendar-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <v-skeleton-loader
                type="list-item"
                class="skeleton-loader-title-with-subtitle"
              ></v-skeleton-loader>
            </v-list-item-title>
            <v-list-item-subtitle>Utcheckning</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item>
          <v-list-item-icon class="my-auto">
            <v-icon>mdi-calendar-arrow-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <span>{{ checkInDateFormatted }}</span>
              <v-chip
                v-if="isDeleted"
                class="ml-2"
                small
                color="error"
                text-color="white"
              >
                Avbokad
              </v-chip>
              <v-progress-circular
                v-else-if="isReversingCheckin"
                indeterminate
                size="24"
                class="ml-6"
                color="primary"
              ></v-progress-circular>
              <v-chip
                v-else-if="isCheckedIn"
                class="ml-2 checked-in-badge"
                :class="{ 'allow-reverse-checkin': isCheckedIn && !isCheckedOut && allowReverseCheckin }"
                small
                color="success"
                text-color="white"
                @click="reverseCheckin"
              >
                <v-icon left>
                  mdi-check
                </v-icon>
                Incheckad
              </v-chip>
              <v-chip
                v-else-if="isDelayedCheckIn"
                class="ml-2"
                small
                color="warning"
                text-color="white"
              >
                Försenad
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle v-if="hasExpectedArrivalTime" v-text="expectedArrivalTimeFormatted"></v-list-item-subtitle>
            <v-list-item-subtitle v-else>Incheckning</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="my-auto">
            <v-icon>mdi-calendar-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              {{ checkOutDateFormatted }}
              <template v-if="isDeleted"></template>
              <v-progress-circular
                v-else-if="isReversingCheckout"
                indeterminate
                size="24"
                class="ml-6"
                color="primary"
              ></v-progress-circular>
              <v-chip
                v-else-if="isCheckedOut"
                class="ml-2 checked-out-badge"
                :class="{ 'allow-reverse-checkout': isCheckedOut && allowReverseCheckout }"
                small
                color="success"
                text-color="white"
                @click="reverseCheckout"
              >
                <v-icon left>
                  mdi-check
                </v-icon>
                Utcheckad
              </v-chip>
              <v-chip
                v-else-if="isDelayedCheckOut"
                class="ml-2"
                small
                color="warning"
                text-color="white"
              >
                Försenad
              </v-chip>
              <v-chip
                v-else-if="isAutoCheckout"
                class="ml-2"
                small
                color="primary"
                text-color="white"
              >
                Automatisk utcheckning
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle>Utcheckning</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>

    <template v-if="!hiddenSections.includes('channel')">
      <template v-if="isLoading">
        <v-list-item>
          <v-list-item-icon class="my-auto">
            <v-icon>mdi-web</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <v-skeleton-loader
                type="list-item"
                class="skeleton-loader-title-with-subtitle"
              ></v-skeleton-loader>
            </v-list-item-title>
            <v-list-item-subtitle>Bokningskanal</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item>
          <v-list-item-icon class="my-auto">
            <v-icon>{{ bookingChannelIcon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ bookingChannel }}
            </v-list-item-title>
            <v-list-item-subtitle>Bokningskanal</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>

    <template v-if="!hiddenSections.includes('confirmed-at')">
      <template v-if="isLoading">
        <v-list-item>
          <v-list-item-icon class="my-auto">
            <v-icon>mdi-calendar-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <v-skeleton-loader
                type="list-item"
                class="skeleton-loader-title-with-subtitle"
              ></v-skeleton-loader>
            </v-list-item-title>
            <v-list-item-subtitle>Bokning mottagen</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item>
          <v-list-item-icon class="my-auto">
            <v-icon>mdi-calendar-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ bookingConfirmedAtFormatted }}
            </v-list-item-title>
            <v-list-item-subtitle>Bokning mottagen</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>

    <template v-if="!hiddenSections.includes('cancellation-policy')">
      <template v-if="isLoading">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-text-box-remove-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <v-skeleton-loader
              type="list-item"
              style="max-width: 200px;"
            ></v-skeleton-loader>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-text-box-remove-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="flex-grow-0 flex-shrink-1" style="flex-basis: unset;">
              {{ booking.cancellationPolicy.name }}
              <v-menu
                v-model="cancellationPolicyCardIsOpen"
                bottom
                right
                transition="scale-transition"
                origin="top left"
                max-height="600"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                  >
                    <v-icon color="info">
                      mdi-information
                    </v-icon>
                  </v-btn>
                </template>
                <cancellation-policy-card
                  :is-open="cancellationPolicyCardIsOpen"
                  :policy="booking.cancellationPolicy"
                  :booking-id="booking.id"
                ></cancellation-policy-card>
              </v-menu>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>

    <template v-if="!hiddenSections.includes('guests')">
      <template v-if="isLoading">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-human-male-boy</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <v-skeleton-loader
              type="list-item"
              style="max-width: 200px;"
            ></v-skeleton-loader>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item>
          <v-list-item-icon class="my-auto">
            <v-icon>mdi-human-male-boy</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ guestsFormatted }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>

  </v-list>
</template>

<script>
import { mapState } from 'vuex'

import CustomerAvatar from '@/components/customer/Avatar.vue'
import CancellationPolicyCard from '@/components/cancellation-policy/CancellationPolicyCard.vue'

export default {
  name: 'BookingDataList',
  components: {
    CustomerAvatar,
    CancellationPolicyCard
  },
  props: {
    booking: Object,
    isLoading: Boolean,
    allowReverseCheckin: Boolean,
    allowReverseCheckout: Boolean,
    insideDialog: Boolean,
    hiddenSections: {
      type: Array,
      default: function () { return [] } // guests, cancellation-policy, channel, confirmed-at, dates, position, customer, booking
    },
    positionClickable: Boolean,
    customerClickable: Boolean,
    bookingClickable: Boolean
  },
  data: () => ({
    cancellationPolicyCardIsOpen: false
  }),
  computed: {
    ...mapState(
      {
        isReversingCheckinBookingIds: state => state.booking.isReversingCheckinBookingIds,
        isReversingCheckoutBookingIds: state => state.booking.isReversingCheckoutBookingIds
      }
    ),
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isReversingCheckin: function () {
      return this.booking && this.isReversingCheckinBookingIds.includes(this.booking.id)
    },
    isReversingCheckout: function () {
      return this.booking && this.isReversingCheckoutBookingIds.includes(this.booking.id)
    },
    isDeleted: function () {
      return this.booking.deleted_at != null
    },
    isCheckedIn: function () {
      return this.booking.checked_in_at != null
    },
    isDelayedCheckIn: function () {
      return !this.isCheckedIn && this.booking.check_in.isBefore(window.dayjs(), 'day')
    },
    isCheckedOut: function () {
      return this.booking.checked_out_at != null
    },
    isDelayedCheckOut: function () {
      return !this.isCheckedOut && this.booking.check_out.isBefore(window.dayjs(), 'day')
    },
    isAutoCheckout: function () {
      return this.booking.auto_checkout_at != null
    },
    isCustomerSelectedPosition: function () {
      return this.booking.is_customer_selected_position
    },
    checkOutDateFormatted: function () {
      if (this.booking.checked_out_at) {
        return window.conversion.toHumanReadableDate(this.booking.checked_out_at)
      } else {
        return window.conversion.toHumanReadableDate(this.booking.check_out)
      }
    },
    checkInDateFormatted: function () {
      if (this.booking.checked_in_at) {
        return window.conversion.toHumanReadableDate(this.booking.checked_in_at)
      } else {
        return window.conversion.toHumanReadableDate(this.booking.check_in)
      }
    },
    bookingConfirmedAtFormatted: function () {
      if (this.booking.confirmed_at) {
        const time = window.conversion.toHumanReadableDate(this.booking.confirmed_at)
        if (this.booking.channel_id === 1) {
          return `${time} från reception`
        } else if (this.booking.channel_id === 2) {
          return `${time} från bokningsportal`
        } else if (this.booking.channel_id === 3) {
          return `${time} från självincheckning`
        }
      }
      return ''
    },
    hasBookingRemarks: function () {
      return this.booking && !!this.booking.remarks
    },
    hasPositionRemarks: function () {
      return this.booking && !!this.booking.position.remarks
    },
    hasExpectedArrivalTime: function () {
      return !this.isCheckedIn && !this.isCheckedOut && !!this.booking.expected_arrival_time
    },
    expectedArrivalTimeFormatted: function () {
      if (!this.hasExpectedArrivalTime || this.isDelayedCheckIn) {
        return ''
      }
      return `Förväntad ankomsttid ${this.booking.expected_arrival_time}`
    },
    guestsFormatted: function () {
      let str = ''
      const adultWord = this.booking.adults === 1 ? 'vuxen' : 'vuxna'
      str = `${this.booking.adults} ${adultWord}`
      if (this.booking.children > 0) {
        str += `, ${this.booking.children} barn`
      }
      if (this.booking.dogs > 0) {
        const dogWord = this.booking.dogs === 1 ? 'hund' : 'hundar'
        str += `, ${this.booking.dogs} ${dogWord}`
      }
      return str
    },
    bookingChannel: function () {
      if (this.booking.channel_id === 1) {
        return 'Receptionen'
      } else if (this.booking.channel_id === 2) {
        return 'Onlinebokning'
      } else if (this.booking.channel_id === 3) {
        return 'Självincheckning'
      }
      return ''
    },
    bookingChannelIcon: function () {
      if (this.booking.channel_id === 1) {
        return 'mdi-room-service'
      } else if (this.booking.channel_id === 2) {
        return 'mdi-web'
      } else if (this.booking.channel_id === 3) {
        return 'mdi-cellphone-key'
      }
      return 'mdi-web'
    }
  },
  methods: {
    gotoPosition: function () {
      if (this.positionClickable) {
        this.$store.dispatch('position/gotoPosition', this.booking.position)
        this.$emit('close')
      }
    },
    openCustomer: function () {
      if (this.customerClickable) {
        this.$store.dispatch('customer/openDialogById', { customerId: this.booking.customer_id, smaller: this.insideDialog && !this.isMobile })
      }
    },
    openBooking: function () {
      if (this.bookingClickable) {
        this.$store.dispatch('booking/openDialogById', { bookingId: this.booking.id })
      }
    },
    reverseCheckin: function () {
      if (!this.isCheckedIn || this.isCheckedOut || !this.allowReverseCheckin) {
        return false
      }
      this.$store.dispatch('booking/reverseCheckin', this.booking.id)
    },
    reverseCheckout: function () {
      if (!this.isCheckedOut || !this.allowReverseCheckout) {
        return false
      }
      this.$store.dispatch('booking/reverseCheckout', this.booking.id)
    }
  }
}
</script>
