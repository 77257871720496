import API from './API.js'
import axios from 'axios'

export default {
  //
  // GET
  //
  getStripeOnboardingLink () {
    return API.httpClient.get('onboarding/stripe-onboarding-link')
  },
  getStripeOnboardingDetails () {
    return API.httpClient.get('onboarding/stripe-onboarding-details')
  },
  getSurfboardOnboardingLink () {
    return API.httpClient.get('onboarding/surfboard-onboarding-link')
  },
  getSurfboardOnboardingDetails () {
    // Specialhantering innan alla fått surfboard
    const noInterceptClient = axios.create({
      ...API.httpClient.defaults,
      withCredentials: true,
      headers: {
        ...API.httpClient.defaults.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CampingOnline-Version': process.env.VUE_APP_VERSION,
        Authorization: API.httpClient.defaults.headers.common.Authorization
      }
    })

    return noInterceptClient.get('onboarding/surfboard-onboarding-details')
      .catch(error => {
        if (error.response && error.response.status === 404) {
          return {
            data: {
              data: {
                onboarding_status: 'UNSUPPORTED',
                company_name: '',
                camping_name: '',
                org_number: '',
                street_address: '',
                postal_code: '',
                city: '',
                phone: '',
                email: '',
                website_url: ''
              }
            }
          }
        }
        throw error
      })
  },

  //
  // POST
  //
  savePositions (positions) {
    const items = positions.map((position) => {
      const item = Object.assign({}, position)
      item.category = null
      return item
    })
    return API.httpClient.post('onboarding/save-positions', {
      positions: items
    })
  }
}
